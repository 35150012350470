import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";
function get_transactions() {
  this.loading = true;
  axiosApiInstance({
    url: this.url + "/payments/transactions",
    method: "get",
  })
    .then((res) => {
      this.items = res.data;
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
}
function format_time(time){
  return time ? moment(time,"YYYY-MM-DD HH:mm:ss").format("LLL") : "-";
}

export { get_transactions,format_time };
