<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">
        <b-link class="text-dark" to="/payments">PAYMENTS</b-link
        ><b-icon-chevron-right></b-icon-chevron-right>
        <b-link class="text-dark font-weight-bold" to="/payments/transactions"
          >TRANSACTIONS</b-link
        >
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <b-button
          v-if="can('create_schedule', 'payments')"
          squared
          variant="primary"
          @click="create_payment_schedule"
        >
          <b-icon-plus></b-icon-plus> New Schedule
        </b-button>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <div class="row m-0">
        <div class="col" cols="8">
          <div class="d-flex align-items-center">
            <b-select
              class="form-control form-select mr-2"
              variant="light"
              v-model="criteria.supplier"
              :options="user_types"
              v-analytics:change="[
                'filter_transactions_by_supplier',
                { element: 'Payments' },
              ]"
              required
            >
            </b-select>
            <b-form class="ml-auto-">
              <b-form-input
                placeholder="Search Name"
                type="search"
                class="search"
                v-model="criteria.searchfield"
              ></b-form-input>
            </b-form>
            <div class="pl-2">
              <date-picker class="pt-1" @dates-selected="cfilterByDate"></date-picker>
            </div>
          </div>
        </div>
        <div class="col-auto ">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="reload">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" v-print="'#TransactionsTable'">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <!-- <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button> -->
            <download-excel
              class="btn btn-opt  primary"
              :data="items"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="text-center" v-show="loading">
        <b-row class="d-flex pt-3 justify-content-center mb-3">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
          ></b-spinner>
        </b-row>
      </div>
      <div class="horizontal-scroll" v-show="items.length > 0">
        <b-table
          id="TransactionsTable"
          :fields="fields"
          bordered
          :items="items"
          :filter="criteria"
          sticky-header="75vh"
          head-variant="light"
          @row-clicked="view_user"
          :filter-function="filterItems"
          hover
          thead-class="bg-primary-tb text-primary-tb border-primary-tb"
        >
          <template #cell(farmer_name)="data">
            {{ data.item.farmer_name }}
            <usertype-pill class="float-right" :user_type="data.item.user_type">
            </usertype-pill>
          </template>
          <template #cell(amount_paid)="data">
            {{ data.item.amount ? data.item.amount.toLocaleString() : 0 }}
          </template>

          <template #cell(account)="data">
            <b-row>
              <b-badge variant="secondary">
                <b>{{ data.item.account }}</b>
              </b-badge>
            </b-row>
            <b-row>
              <span class="text-primary">{{ data.item.ac_name }}</span>
            </b-row>
          </template>
          <template #cell(sending)="data">
            <span
              v-text="
                get_sending_charge(
                  data.item.payment,
                  data.item.ac_number,
                  data.item.method
                ).toLocaleString()
              "
            >
            </span>
          </template>

          <template #cell(time)="data">
            {{ format_time(data.item.time) }}
          </template>
          <template #cell(payment_state)="data">
            {{
              capitalize(
                data.item.payment_state
                  ? data.item.payment_state
                  : data.item.payment_status
              )
            }}
          </template>
          <template #cell(payment_method)="data">
            {{
              capitalize(
                data.item.payment_method ? data.item.payment_method : ""
              )
            }}
          </template>
        </b-table>
        <hr />
      </div>
      <add-payment-schedule
        @SuccessfulPayment="reload()"
      ></add-payment-schedule>
      <farmer-modal @Success="reload" :schedule="schedule"></farmer-modal>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {
  get_charges,
  get_tax_charge,
  get_withdrawal_charge,
  get_sending_charge,
  total,
  total_withdrawal,
  total_amount,
  total_sending,
  total_tax,
} from "../schedule/actions.js";
import { format_time, get_transactions } from "./actions.js";

import { can } from "@/modules/auth/utils.js";
import { bus } from "@/main.js";
import AddPaymentSchedule from "@/components/add_payment_schedule/AddPaymentSchedule.vue";
import UsertypePill from "../../components/usertype_pill/UsertypePill.vue";
import FarmerModal from "@/components/farmer_modal/Farmer.vue";
import DatePicker from "@/modules/produce/components/ProduceDatePicker.vue";
import dateMixin from "@/modules/produce/date_mixin";

export default {
  components: {
    FarmerModal,
    UsertypePill,
    AddPaymentSchedule,
    DatePicker,
  },
  mixins:[dateMixin],
  computed: {
    url() {
      return (
        process.env.VUE_APP_BACKEND +
        "/api/v3/" +
        this.$store.state.project_id +
        this.$store.state.url_prefix
      );
    },
  },
  data() {
    return {
      searchfield: "",
      fields: [
        { key: "tag", label: "Ref" },
        {
          key: "farmer_name",
          label: "Name",
          stickyColumn: true,
        },
        { key: "payment_method", label: "Method" },
        { key: "account", label: "Account" },
        // { key: "ac_name", label: "A/c name" },
        { key: "amount_paid", label: "Amount" },
        { key: "payment_state", label: "Status" },
        "time",
      ],
      items: [],
      isAdding: false,
      schedule_time: "",
      schedule: {},
      sending: [],
      withdrawal: [],
      user_types: [
        { value: null, text: "All Suppliers" },
        { value: "agent", text: "Agents" },
        { value: "farmer", text: "Farmers" },
      ],
      tax: [],
      loading: false,
      crops: [],
      currency: "UGX",
      money: "money",
      account: "account",
      include_withdrawal: 0,
      include_tax: 0,
      criteria: {
        supplier: null,
        searchfield: null,
        selectedPeriod: [""],
      },
    };
  },
  methods: {
    format_time,
    get_transactions,
    can,
    get_charges,
    get_tax_charge,
    get_withdrawal_charge,
    get_sending_charge,
    total,
    total_withdrawal,
    total_amount,
    total_sending,
    total_tax,
    create_payment_schedule() {
      bus.$emit("add-payment-schedule", { load: true });
    },
    reload() {
      this.get_transactions();
    },
    view_user(item) {
      bus.$emit("farmer-view", {
        name: item.farmer_name,
        data: item,
        farmer_id: item.user_id ? item.user_id : item.farmer_id,
        tab: "payments",
      });
    },
    capitalize(word) {
      return word
        ? word
            .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
            .trim()
        : "";
    },
    filterItems(row, criteria) {
      let filters = [];
      if (criteria.supplier) {
        filters.push(row.user_type == criteria.supplier);
      }
      if (criteria["selectedPeriod"].length==2) {
        filters.push(
          moment(row["time"]).isBetween(...this.criteria.selectedPeriod)
        );
      }
      if (criteria.searchfield) {
        filters.push(
          row["farmer_name"].toLowerCase().includes(criteria.searchfield.toLowerCase())
        );
      }
      return !filters.includes(false);
    },
  },
  mounted() {
    this.get_transactions();
    this.get_charges();
    this.dateInit();
  },
};
</script>

<style scoped>
.no-decorate {
  text-decoration: none;
}
.w100 {
  max-width: 100%;
}
.table tbody .edit {
  padding: 8px 2px !important;
}
.horizontal-scroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
</style>
